
//import formJSON from '../formElement.json';
import { useState, useEffect, useRef } from 'react';
import Element from './Element';
import { useNavigate } from "react-router-dom";
import { FormContext } from '../FormContext';
//import $ from 'jquery';
// import { Button } from 'bootstrap';
import { useTime } from 'react-timer-hook';

import Swal from 'sweetalert2';
import { FcAlarmClock } from 'react-icons/fc';
import { FcEndCall } from 'react-icons/fc';
import { FcCallTransfer } from 'react-icons/fc';
import { BiCoffee } from 'react-icons/bi';
import { FiSave } from 'react-icons/fi';
import { FaBluetooth, FaPlay } from "react-icons/fa";
import { FaPause } from "react-icons/fa";
import { FcConferenceCall } from 'react-icons/fc';
import { BsTelephoneX } from "react-icons/bs";
import { MdOutlinePhoneForwarded } from 'react-icons/md';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Modal from './Modal';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import Callback from './Callback';
import { CiPause1 } from "react-icons/ci";
import { CiPlay1 } from "react-icons/ci";
import { FcCallback } from "react-icons/fc";

import { FcStart } from "react-icons/fc";








function Crm() {
  // //console.log(">>>>>>>out>>>>>>>"

  const playBeep = () => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const oscillator = audioContext.createOscillator();
    oscillator.type = 'sine'; // Set the oscillator type
    oscillator.frequency.setValueAtTime(300, audioContext.currentTime); // Frequency of the beep
    oscillator.connect(audioContext.destination); // Connect the oscillator to the audio destination
    oscillator.start(); // Start the oscillator
    oscillator.stop(audioContext.currentTime + 0.1); // Stop the oscillator after 0.1 seconds

  };


  const location = useLocation();

  const [mmargin, setMmargin] = useState(true);

  useEffect(() => {
    const checkMobile = () => {
      setMmargin(window.innerWidth > 768);
    };

    checkMobile();



    window.addEventListener('resize', checkMobile);

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, [location.pathname]);





  let history = useNavigate();
  const ref = useRef(null)
  const refconf = useRef(null)

  const refclicktocall = useRef(null)
  const refbreakmodel = useRef(null)
  const refbreakendmodel = useRef(null)

  const [disp, setDisp] = useState("NA");



  // const [busyflag, setBusyflag] = useState();

  const transferAgent = async () => {

    ref.current.click();
    //alert("clicked>>>>>>>>>");

  }


  const handleClickApicall = (e1) => {
    //const queryString = new URLSearchParams({ name, age }).toString();
    window.open(`https://admin.baalbecho.com/admin/callingData?mobile=${e1}`, "_blank");
  };

  const transferAgentfun = async () => {
    //preventDefault();
    ref.current.click();
    //console.log(">>>>number>>>>"+number)
    var formdata = new FormData()
    formdata.append('number', number)
    const response = await fetch(process.env.REACT_APP_IP + "/User/Transfer", {
      method: 'POST',
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      headers: {


        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
      body: formdata
      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });
    const json = await response.json()
    // console.log(json);
    if (json.type === "OK") {
      // props.showAlert(json.msg, "success");
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: json.msg,
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
    } else {
      //props.showAlert(json.msg, "danger");
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: json.msg,
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
    }

    setNumber('')


  }

  useEffect(() => {
    document.title = "Live Dashboard"
  }, []);

  const [hidecustmobile, setHidecustmobile] = useState();
  const [hidenum, setHidenum] = useState(false);
  const userCamphide = async (e) => {
    fetch(process.env.REACT_APP_IP + "/User/getCampaignHideStatus", {
      method: 'POST',
      headers: {
        // 'Content-Type': 'application/json',

        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }

      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    })
      .then(response => {
        // Check if the response is successful (status code 200-299)
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Parse the response as text
        return response.text();
      })
      .then(data => {
        // Set the response in the state
        if (data === "true") {
          setHidenum(true);
          //alert(data)
        }


      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });


  }

  useEffect(() => {
    userCamphide()

  }, [])



  useEffect(() => {
    if (localStorage.getItem('token')) {
      // history('/Crm');
      // getNotes();
      // eslint-disable-next-line
      //const
    } else {
      history('/');
    }
  }, [])


  const [selectedDate, setSelectedDate] = useState('');

  const handleDateChange = (date) => {
    //alert(date)
    setSelectedDate(date);
    // alert(moment(selectedDate).format('YYYY-MM-DD HH:mm:ss'))
  };

  const clicktocallprog = async (e) => {
    // alert("clicked>>>>clicktocallprog>>>>>");
    const response = await fetch(process.env.REACT_APP_IP + "/User/Progcall", {
      method: 'POST',
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      headers: {


        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
      // body: formdata
      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });
    const json = await response.json()
    // console.log(json);
    if (json.type === "OK") {
      // props.showAlert(json.msg, "success");
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: json.msg,
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
    } else {
      //props.showAlert(json.msg, "danger");
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: json.msg,
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
    }

  }

  const clicktocall = async (e) => {

    refclicktocall.current.click();
    // console.log(">>>>>clicktocall>>>>>"+e)
    //alert("clicked>>>>>>>>>");

  }


  const onPause = async (e) => {

    const response = await fetch(process.env.REACT_APP_IP + "/User/onPause", {
      method: 'POST',
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      headers: {


        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
      // body: formdata
      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });
    const json = await response.json()
    // console.log(json);
    if (json.type === "OK") {
      // props.showAlert(json.msg, "success");
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: json.msg,
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
    } else {
      //props.showAlert(json.msg, "danger");
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: json.msg,
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
    }



  }



  const redialsubmit = async () => {
    // e.preventDefault();
    // refclicktocall.current.click();
    //console.log(">>>>number>>>>"+number)
    // var formdata = new FormData()
    //  formdata.append('number', number)
    const response = await fetch(process.env.REACT_APP_IP + "/User/Redial", {
      method: 'POST',
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      headers: {


        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
      // body: formdata
      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });
    const json = await response.json()
    // console.log(json);
    if (json.type === "OK") {
      // props.showAlert(json.msg, "success");
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: json.msg,
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
    } else {
      //props.showAlert(json.msg, "danger");
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: json.msg,
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
    }

    //setNumber('')


  }





  const submitconfform = async (e) => {
    e.preventDefault();
    refconf.current.click();
    //console.log(">>>>number>>>>"+number)
    var formdata = new FormData()
    formdata.append('number', number)
    const response = await fetch(process.env.REACT_APP_IP + "/User/ConfStart", {
      method: 'POST',
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      headers: {


        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
      body: formdata
      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });
    const json = await response.json()
    // console.log(json);
    if (json.type === "OK") {
      // props.showAlert(json.msg, "success");
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: json.msg,
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
    } else {
      //props.showAlert(json.msg, "danger");
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: json.msg,
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
    }

    setNumber('')


  }


  const clicktocallsubmit = async (e) => {
    e.preventDefault();
    refclicktocall.current.click();
    //console.log(">>>>number>>>>"+number)
    var formdata = new FormData()
    formdata.append('number', number)
    const response = await fetch(process.env.REACT_APP_IP + "/User/Clicktocallnp", {
      method: 'POST',
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      headers: {


        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
      body: formdata
      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });
    const json = await response.json()
    // console.log(json);
    if (json.type === "OK") {
      // props.showAlert(json.msg, "success");
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: json.msg,
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
    } else {
      //props.showAlert(json.msg, "danger");
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: json.msg,
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
    }

    setNumber('')


  }


  const breakFun = async () => {

    //setBreakpwd('')
    // setBpwd('')
    refbreakmodel.current.click();
    //alert("clicked>>>>>>>>>");

  }
  const confAgent = async () => {

    refconf.current.click();
    //alert("clicked>>>>>>>>>");

  }

  //All Dispo

  const optionforDispo = async (e) => {


    setDisp("NA")

    try {
      const respp = await fetch(process.env.REACT_APP_IP + "/User/AllDispoList", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',

          "Authorization": `Bearer ${localStorage.getItem('token')}`
        }

        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
      })
      const json = await respp.json()
      console.log(json.length)

      var elelist = document.getElementById('dispid');
      elelist.innerHTML = "" + ('<option value="NA">--Select Dispo---</option>');
      elelist.innerHTML = elelist.innerHTML + ('<option value="Default">Default</option>');
      // elelist.innerHTML = elelist.innerHTML + ('<option value="ConvertToLead">ConvertToLead</option>');
      //  elelist.innerHTML = elelist.innerHTML + ('<option value="CALLBACK">CALLBACK</option>');

      for (var i = 0; i < json.length; i++) {
        // POPULATE SELECT ELEMENT WITH JSON.
        elelist.innerHTML = elelist.innerHTML +
          '<option  value="' + json[i]['dispo'] + '|' + json[i]['lead'] + '|' + json[i]['caltype'] + '">' + json[i]['dispo'] + '</option>';
      }
    } catch (error) {
      console.log("Error in crm AllDispoList ")
    }
  }



  //All optionforBreak

  const optionforBreak = async (e) => {
    const respp = await fetch(process.env.REACT_APP_IP + "/User/AllBreakList", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',

        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }

      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    })
    const json = await respp.json()
    console.log(json.length)

    var elelist = document.getElementById('breakreasonid');
    elelist.innerHTML = "" + ('<option value="Break-NoReason">Select Break</option>');


    for (var i = 0; i < json.length; i++) {
      // POPULATE SELECT ELEMENT WITH JSON.
      elelist.innerHTML = elelist.innerHTML +
        '<option  value="' + json[i]['breakreason'] + '">' + json[i]['breakreason'] + '</option>';
    }
    elelist.innerHTML = elelist.innerHTML + ('<option value="Break-Default">Default</option>');
  }

  const [missedagenttodaycallback, setMissedagenttodaycallback] = useState("0");

  const missedagenttodaycallbackfun = async (e) => {

    try {
      fetch(process.env.REACT_APP_IP + "/User/countMissedTodayCallbackByUsername", {
        method: 'GET',
        headers: {
          // 'Content-Type': 'application/json',

          "Authorization": `Bearer ${localStorage.getItem('token')}`
        }

        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
      })
        .then(response => {
          // Check if the response is successful (status code 200-299)
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          // Parse the response as text
          return response.text();
        })
        .then(data => {
          // Set the response in the state
          setMissedagenttodaycallback(data);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    } catch (error) {
      console.log("Error in crm AgentTotalcall")
    }

  }

  useEffect(() => {
    missedagenttodaycallbackfun()
    const timer = setInterval(async () => {

      missedagenttodaycallbackfun()
      return () => clearInterval(timer);
    }, 60000)
  }, [])

  const [agenttotalcallback, setAgenttotalcallback] = useState("0");

  const agenttotalcallbackfun = async (e) => {

    try {
      fetch(process.env.REACT_APP_IP + "/User/countTotalCallbackByUsername", {
        method: 'GET',
        headers: {
          // 'Content-Type': 'application/json',

          "Authorization": `Bearer ${localStorage.getItem('token')}`
        }

        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
      })
        .then(response => {
          // Check if the response is successful (status code 200-299)
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          // Parse the response as text
          return response.text();
        })
        .then(data => {
          // Set the response in the state
          setAgenttotalcallback(data);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    } catch (error) {
      console.log("Error in crm AgentTotalcall")
    }

  }

  useEffect(() => {
    agenttotalcallbackfun()
    const timer = setInterval(async () => {

      agenttotalcallbackfun()
      return () => clearInterval(timer);
    }, 60000)
  }, [])


  const [agenttotaltodaycallback, setAgenttotaltodaycallback] = useState("0");

  const agenttotaltodaycallbackfun = async (e) => {

    try {
      fetch(process.env.REACT_APP_IP + "/User/countAllActiveTodayCallbackByUsername", {
        method: 'GET',
        headers: {
          // 'Content-Type': 'application/json',

          "Authorization": `Bearer ${localStorage.getItem('token')}`
        }

        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
      })
        .then(response => {
          // Check if the response is successful (status code 200-299)
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          // Parse the response as text
          return response.text();
        })
        .then(data => {
          // Set the response in the state
          setAgenttotaltodaycallback(data);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    } catch (error) {
      console.log("Error in crm AgentTotalcall")
    }

  }

  useEffect(() => {
    agenttotaltodaycallbackfun()
    const timer = setInterval(async () => {

      agenttotaltodaycallbackfun()
      return () => clearInterval(timer);
    }, 60000)
  }, [])

  const [agenttotalcall, setAgenttotalcall] = useState("0");

  const agenttotalcallfun = async (e) => {

    try {
      fetch(process.env.REACT_APP_IP + "/User/AgentTotalcall", {
        method: 'GET',
        headers: {
          // 'Content-Type': 'application/json',

          "Authorization": `Bearer ${localStorage.getItem('token')}`
        }

        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
      })
        .then(response => {
          // Check if the response is successful (status code 200-299)
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          // Parse the response as text
          return response.text();
        })
        .then(data => {
          // Set the response in the state
          setAgenttotalcall(data);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    } catch (error) {
      console.log("Error in crm AgentTotalcall")
    }

  }

  useEffect(() => {
    agenttotalcallfun()
    const timer = setInterval(async () => {

      agenttotalcallfun()
      return () => clearInterval(timer);
    }, 60000)
  }, [])

  const [agenttotallogintime, setAgenttotallogintime] = useState("NA");

  const logintimefun = async (e) => {
    fetch(process.env.REACT_APP_IP + "/User/Agentlogintime", {
      method: 'GET',
      headers: {
        // 'Content-Type': 'application/json',

        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }

      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    })
      .then(response => {
        // Check if the response is successful (status code 200-299)
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Parse the response as text
        return response.text();
      })
      .then(data => {
        // Set the response in the state
        setAgenttotallogintime(data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });


  }

  useEffect(() => {
    logintimefun()
    const timer = setInterval(async () => {

      logintimefun()
      return () => clearInterval(timer);
    }, 60000)
  }, [])

  const [sipstatus, setSipstatus] = useState("")

  const sipStatus = async (e) => {
    fetch(process.env.REACT_APP_IP + "/User/getSipStatus", {
      method: 'POST',
      headers: {
        // 'Content-Type': 'application/json',

        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }

      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    })
      .then(response => {
        // Check if the response is successful (status code 200-299)
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Parse the response as text
        return response.text();
      })
      .then(data => {
        // Set the response in the state
        setSipstatus(data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });


  }

  useEffect(() => {
    sipStatus()
    const timer = setInterval(async () => {

      sipStatus()
      return () => clearInterval(timer);
    }, 60000)
  }, [])

  useEffect(() => {
    optionforBreak()
  }, [])

  useEffect(() => {
    optionforDispo()
  }, [])

  const hangupAgent = async () => {
    // e.preventDefault();
    // var formdata = new FormData()
    // formdata.append('id', e)
    const response = await fetch(process.env.REACT_APP_IP + "/User/HangupAgentApi", {
      method: 'POST',
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      headers: {
        //'Content-Type': 'application/json',

        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });
    const json = await response.json()
    // console.log(json);
    if (json.type === "OK") {
      // props.showAlert(json.msg, "success");
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: json.msg,
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
    } else {
      //props.showAlert(json.msg, "danger");
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: json.msg,
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
    }



  }


  const [agentname, setAgentname] = useState();
  const [number, setNumber] = useState();
  const [remark, setRemarks] = useState();
  const [busyflagnew, setBusyflagnew] = useState();
  const [elements, setElements] = useState(null);
  const [userstatus, setuserstatus] = useState(null);
  const [camp, setcamp] = useState();
  const [custmobile, setcustmobile] = useState();
  const [type, setType] = useState();
  const [loginstatus, setloginstatus] = useState("NA");


  const [callanstime, setCallanstime] = useState();

  const [useridcrm, setUseridcrm] = useState();

  const [sip, setsip] = useState();
  //  //console.log(">>>>>>>true>>>>"+busyflag);
  var busyflag = true;
  useEffect(() => {

    // setBusyflag(true);
    const timer = setInterval(async () => {
      try {

        if (localStorage.getItem('roles') === 'ROLE_USER') {


          const userinfo = await fetch(process.env.REACT_APP_IP + "/User/GetUserinfo", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
              'Content-Type': 'application/json',

              "Authorization": `Bearer ${localStorage.getItem('token')}`
            }

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
          })
          const json = await userinfo.json();

          //alert(">>>>"+json.message)

          if (json.message === "Unauthorized") {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: "Token Expire Plz Login Again",
              background: '#e9ecef',
              // title: 'Done Successfully ',
              //background: primary,
              showConfirmButton: false,
              timer: 2500
            })

          }


          setuserstatus(json.userpro.status);
          setcamp(json.userpro.campaign);
          setsip(json.userpro.sip)
          setcustmobile(json.userpro.custmobile)
          setHidecustmobile("XXXXXX" + json.userpro.custmobile.slice(-4));
          setloginstatus(json.userpro.loginmode)
          setCallanstime(json.userpro.callanstime)
          setType(json.userpro.type)
          setUseridcrm(json.userid)
          //console.log(">>>>>>>>>userstatus>>>>>>>" + userstatus + ">>>>>>>>>>>" + json.userpro.status)

          if (json.userpro.loginmode != "FREE" && json.userpro.loginmode != "PAUSE") {
            setIsOpen(true)
          }

          if (json.userpro.status === "BUSY" || json.userpro.status === "HANGUP") {
            if (busyflag) {

              const response = await fetch(process.env.REACT_APP_IP + "/User/GetCampaignField", {
                method: 'POST',
                headers: {
                  "Authorization": `Bearer ${localStorage.getItem('token')}`
                }
              });
              const json2 = await response.json()


              // alert("ok"+json2.fields.length)
              if (json2.fields.length > 0) {
                setElements(json2);
              } else {
                setElements({
                  "fields": [
                    {
                      "id": 0,
                      "fieldlabel": "NA",
                      "fieldtype": "NA"
                    }
                  ]
                });
              }




              busyflag = false;
              // if (json.userpro.status === "BUSY") {
              //   playBeep();
              //  }

              // console.log(">>>>>>>>>>>"+busyflag)
            }
            // console.log(">>>>>>>>>>>"+busyflag)



            //console.log(">>>>>>>>>if called>>>>>>>>" + busyflag)
          }
          if (json.userpro.status === "FREE") {
            busyflag = true;
            //setuserstatus(json.userpro.status);
          }

          // }else{
          //   //console.log(">>>>>>>>>else called>>>>>>>>"+busyflag) 
          // }


        }
      } catch (error) {
        console.log("Error catch")
      }
      return () => clearInterval(timer);
    }, 3000)


  }, [])






  const { fields, page_label } = elements ?? {}



  const handleSubmit = async (event) => {

    //alert(">>>"+disp)

    event.preventDefault();
    busyflag = true;

    if (disp === 'NA') {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: "Select Disposition",
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
      return
    }

    //alert(selectedDate)
    if (selectedDate === '' && disp === 'CALLBACK') {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: "Select Date For CALLBACK",
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
      return
    }

    //alert(selectedDate)
    if (selectedDate === '' && vdate === 'Yes') {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: "Select Date First",
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
      return
    }



    //if no need remark mandatory
    // if(remark === '' || remark === undefined)
    // {
    //   alert("Fill Remarks")
    //   return
    // }

    //console.log("busyflag>>>>>>>>>" + busyflag)
    //setuserstatus("");
    //setElements(null);

    // alert(">>>"+remark)
    //alert(">>>>>>>>>"+selectedDate)
    // if(disp==="CALLBACK")
    // {
    //   if(selectedDate>10000)
    // {
    //   alert(moment(selectedDate).format('YYYY-MM-DD HH:mm:ss'))
    // }else{
    //   alert("####"+moment(selectedDate).format('YYYY-MM-DD HH:mm:ss'))
    // }

    // }



    //const elll=JSON.stringify(elements)

    ////console.log("lllll"+JSON.stringify(elements))
    //alert(elements.fields)
    // alert(elements.fields.length)

    //  if(elements.fields.length < 1)
    //   {
    //     alert("ok")
    //     setElements({
    //       "fields": [
    //           {
    //               "id": 0,              
    //               "fieldlabel": "NA",
    //               "fieldtype": "NA"
    //           }
    //       ]
    //   });
    //   }

    //alert(elements.fields.length+"3333")

    const result = elements.fields.map(item => {
      return {
        //id: parseInt(item.id),
        id: item.id,

        fieldvalue: item.fieldvalue,
        disone: disp,
        leaddate:vdate,
        lead:llead,
        callback: moment(selectedDate).format('YYYY-MM-DD HH:mm:ss'),
        campname: remark

      };
    });



    //console.log(result);

    // let payload = {
    //   fields: elements
    // };
    // var formdata = new FormData()
    //  formdata.append('remark', remark)
    // formdata.append('abc', result)

    const response = await fetch(process.env.REACT_APP_IP + "/User/SubmitCrmdata", {
      method: 'POST',
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      headers: {
        'Content-Type': 'application/json',

        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
      //body: formdata,
      body: JSON.stringify(result)
      //  body:JSON.stringify({remarks: remark})
      // body:JSON.stringify(remark)
      //body: JSON.stringify({result:result,remark:remark})
      // body: payload
      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });
    const json = await response.json()
    if (json.type === "OK") {
      // props.showAlert(json.msg, "success");
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: json.msg,
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
    } else {
      //props.showAlert(json.msg, "danger");
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: json.msg,
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
    }
    setRemarks('')
    //setSelectedDate(new Date())
    //handleDateChange('')

    setCb("NA")
    optionforDispo()
    // setBusyflag(true);

    // const resulth = elements.fields.map(item => {
    //   return {
    //     //id: parseInt(item.id),
    //     id: item.id,

    //     fieldvalue: ""


    //   };
    // });
    setSelectedDate('')
    setElements(null)

    // setBusy(true)
    ////console.log("evvvvvv"+event)


  }



  const handleChange = (id, event) => {
    const newElements = { ...elements }
    newElements.fields.forEach(field => {
      const { fieldtype, fieldid } = field;
      if (id === fieldid) {
        switch (fieldtype) {
          case 'DROPDOWN':
            // alert("ok");
            field['fieldvalue'] = event.target.value;
            break;

          case 'Label':
            // alert("ok");
            field['fieldvalue'] = event.target.value;
            break;

          default:
            field['fieldvalue'] = event.target.value;
            break;
        }


      }
      setElements(newElements)
    });
    //console.log(elements)
  }

  const {
    seconds,
    minutes,
    hours,
    ampm,
  } = useTime({ format: '24-hour' });

  const handleChangetxtarea = (event) => {
    setRemarks(event.target.value)
  }

  const [breakreason, setBreakreason] = useState("Break-NoReason")
  const [breakpwd, setBreakpwd] = useState()
  const [bpwd, setBpwd] = useState()



  const BreakEnd = async (e) => {
    e.preventDefault();
    var formdata = new FormData()
    formdata.append('bpwd', bpwd)
    const response = await fetch(process.env.REACT_APP_IP + "/User/EndBreak", {
      method: 'POST',
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      headers: {


        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
      body: formdata
      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });
    const json = await response.json()
    // setIsOpen(true);
    if (json.type === "OK") {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: json.msg,
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
      setIsOpen(false);
    } else {
      //props.showAlert(json.msg, "danger");
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: json.msg,
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
      setIsOpen(true);
    }
    // setBpwd('')


  }

  const onBreakSubmit = async (e) => {
    e.preventDefault();
    refbreakmodel.current.click();


    //console.log(">>>>number>>>>"+number)
    var formdata = new FormData()
    formdata.append('breakreason', breakreason)
    formdata.append('breakpwd', breakpwd)
    const response = await fetch(process.env.REACT_APP_IP + "/User/StartBreak", {
      method: 'POST',
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      headers: {


        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
      body: formdata
      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });
    const json = await response.json()
    // console.log(json);
    if (json.type === "OK") {
      // props.showAlert(json.msg, "success");
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: json.msg,
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
    } else {
      //props.showAlert(json.msg, "danger");
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: json.msg,
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
    }
    setIsOpen(true);
    //refbreakendmodel

    //setBreakreason('Break-NoReason')
    // setBreakreason
    //setBpwd('')
    setBreakpwd('')


  }


  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };


  const [testcb, setTestcb] = useState("NA");

  const [cb, setCb] = useState("NA");

  const [ptp, setPtp] = useState("NA");

  const [vdate, setVdate] = useState("NA");
  const [llead, setLlead] = useState("NA");

  const setCallback = (e) => {
     if (e === "CALLBACK") {
      setCb("CALLBACK")

    }else if (e === "PTP") {
      setPtp("PTP")

    } else {

      setCb("NA")
    }
    // alert(e)

    //var abc=e;
    //setSelectedDate('')
    const separatedData = e.split("|");
    //alert(separatedData[0])
    //alert(separatedData[1])
    //alert(separatedData[2])

    //setDisp(e);
    setDisp(separatedData[0])
   
    setTestcb(separatedData[0])

    // alert(separatedData[1])
    if (separatedData.length == 3) {
     
      if (separatedData[2] === "Yes") {
        // alert("setting")
        setVdate("Yes")
      } else {
        setVdate("No")
      }
      if (separatedData[1] === "Yes") {
        // alert("setting")
        setLlead("Yes")
      } else {
        setLlead("No")
      }
    } else {
      setVdate("No")
      setLlead("No")
    }




    // if (e === "CALLBACK") {
    //   setCb("CALLBACK")

    // }else if (e === "PTP") {
    //   setPtp("PTP")

    // } else {

    //   setCb("NA")
    // }


  }



  return (
    <>






      <div className=''>
        {/* <button onClick={handleOpenModal}>Open Modal</button> */}
        <Modal isOpen={isOpen} onClose={handleCloseModal}>
          <h2 className='text-center card-header'>ON-Break</h2>
          <div className='breakform'>
            <form className='card ' onSubmit={BreakEnd}>
              <div className='text-center card-header mt-1 mb-1 '>Enter PWD To Remove Break</div>
              <div className='p-3'>

                <input type="text" className='form-control mt-1 mb-2'
                  //value={bpwd}
                  onChange={(e) => setBpwd(e.target.value)}
                  placeholder='Enter Pwd' required />
                <input type="submit" className='btn btn-primary text-white form-control mt-3 ' value="submit" />
              </div>

            </form>

          </div>

        </Modal>
      </div>
      {/* <div style={{ marginTop: "65px" }}> */}
      <FormContext.Provider value={{ handleChange }}>
        {/* <div className="card-body"> */}
        <div className="mt-1 ms-1 me-3 ">



          <div className='row '>





            <div className='col-md-2'>
              <div className='row p-1'>
                {/* <div className='col-md-1'>
                           </div> */}
                <div className='col-md-12'>


                  <div className={`mb-1 ${userstatus === "BUSY" ? "" : userstatus === "HANGUP" ? "" : " hide"}`} >
                    {/* <div className='card-header'>
    <h4 className='text-center text-black'>Disposition</h4>
  </div> */}


                    <div className='row'>
                      <div className='col-sm-12 col-12'>
                        <select className="form-select mt-1 mb-1" name="dispo" id="dispid" autoFocus required
                          // value={disp}
                          onChange={(e) => setCallback(e.target.value)}
                        >
                        </select>
                      </div>
                    </div>




                    <div className={` ${cb === "CALLBACK" ? "" :
                      ptp === "PTP" ? "" :
                        vdate === "Yes" ? "" : "hide"}`}>
                      <span style={{ fontSize: "8px", fontWeight: "600" }}>Pick Time</span>
                      <DateTime
                        // value={selectedDate}
                        className='maxwidthcust'
                        onChange={handleDateChange}
                        timeFormat="HH:mm:ss"
                        required
                      />




                      {/* {selectedDate && <p>Selected Date: {selectedDate.toString()}</p>} */}

                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12 col-6'>


                      <button className={`${userstatus === "HANGUP" ? "col-sm-12 col-12 btn btn-success rounded btn-sm " : "col-sm-12 col-12 disabled btn btn-success rounded btn-sm "}`}
                        onClick={(e) => handleSubmit(e)}>
                        <div className='float-start '><FiSave size="14px" className="" /></div>

                        <div className='float-end '>
                          Save
                        </div>
                      </button>



                    </div>

                    <div className='col-md-12 col-6'>
                      <div className='col-12'>
                        <button className={`  ${mmargin ? "mt-1" : ""}  ${userstatus === "BUSY" ? "col-sm-12 col-5 btn btn-danger rounded btn-sm  " : "col-sm-12 col-12 disabled btn btn-danger rounded btn-sm "}`}
                          onClick={() => hangupAgent()} >
                          <div className='float-start '><BsTelephoneX size="20px" className="me-3 text-white" /></div>

                          <div className='float-end '>END</div>


                        </button>
                      </div>
                    </div>
                  </div>


                  <div className={`row ${mmargin ? "" : "mt-1"}`}>
                    <div className='col-md-12 col-6'>
                      <button className={`col-sm-12 col-12 ${mmargin ? "mt-1 mb-1 btn btn-warning rounded btn-sm" : "btn btn-warning rounded btn-sm"}`} onClick={() => transferAgent()}>
                        <div className='float-start '><FcCallTransfer size="15px" className="me-3" /></div>

                        <div className='float-end '>Trans</div></button>
                    </div>


                    <div className='col-md-12 col-6'>

                      <button className=' col-sm-12  col-12  btn btn-info rounded btn-sm ' onClick={() => confAgent()} >
                        <div className='float-start '>
                          <FcConferenceCall size="15px" className="me-3" />
                        </div>
                        <div className='float-end '>Conf</div>
                      </button>

                    </div>
                  </div>

                  <div className='row mt-1'>
                    <div className={`col-md-12 col-6 `}>
                      <button className={`col-sm-12 col-12 ${mmargin ? "mb-1 " : ""} ${(userstatus === "HANGUP") || (userstatus === "BUSY") ? "btn btn-primary rounded btn-sm disabled" : "btn btn-primary rounded btn-sm"}`} onClick={(e) => clicktocall(e)} >
                        <div className='float-start '>
                          <MdOutlinePhoneForwarded size="20px" className="me-3" />

                        </div>
                        <div className='float-end '>C2C</div>
                      </button>

                    </div>

                    <div className='col-md-12 col-6'>
                      <button className={`${(userstatus === "HANGUP") || (userstatus === "BUSY") ? "disabled col-sm-12 col-12   btn btn-primary rounded btn-sm" : " col-sm-12 col-12   btn btn-primary rounded btn-sm"} `} onClick={(e) => redialsubmit(e)} >
                        <div className='float-start '>
                          <MdOutlinePhoneForwarded size="20px" className="me-3" />
                        </div>
                        <div className='float-end '>
                          Redial
                        </div>
                      </button>

                    </div>
                  </div>

                  <div className='mt-1 row'>
                    <div className='col-md-12 col-6 '>
                      <button className='col-sm-12 col-12   btn btn-primary rounded btn-sm ' onClick={(e) => breakFun(e)} >
                        <div className='float-start '>
                          <BiCoffee size="20px" className="" />
                        </div>
                        <div className='float-end'>

                          Break
                        </div>
                      </button>
                    </div>
                    <div className={` col-md-12 col-6 ${mmargin ? "mt-1" : ""}`}>
                      <button className={`${(userstatus === "HANGUP") || (userstatus === "BUSY") ? "disabled  col-sm-12 col-12   btn btn-primary rounded btn-sm" : " col-sm-12 col-12   btn btn-primary rounded btn-sm"} `} onClick={(e) => clicktocallprog(e)} >


                        <div className='float-start '>
                          <MdOutlinePhoneForwarded size="20px" className="me-3" />
                        </div>

                        <div className='float-end '>Prog</div>



                      </button>
                    </div>


                  </div>
                  <div className=' row mt-1'>
                    <div className='col-sm-12 col-6  '>
                      <div className='badge bg-primary  col-sm-12 col-12  ' >
                        {/* <div className='col-sm-12 col-6 bg-warning rounded sdfsdfsd p-1'> */}

                        {loginstatus === "PAUSE" ?
                          <div className=''>
                            <div style={{ fontSize: "10px" }} className='float-start mt-1'>
                              <b className=''>

                                {/* <MdOutlinePhoneForwarded size="20px" className="me-3" /> */}
                                <CiPause1 size="20px" className="me-1" />

                                {loginstatus} :  </b>
                            </div>
                            <div style={{ fontSize: "10px" }} className='float-end text-black mt-1'>
                              <label className="toggle-switch ">
                                <input type="checkbox" checked={loginstatus === "FREE"} onChange={() => onPause()} />
                                <span className="switch " />
                              </label>

                            </div>
                          </div>

                          :
                          <div className=''>
                            <div style={{ fontSize: "10px" }} className='mt-1 float-start'>
                              <b className=''>

                                {/* <MdOutlinePhoneForwarded size="20px" className="me-3" /> */}
                                <CiPlay1 size="20px" className="me-1" />

                                {loginstatus}  </b>
                            </div>

                            <div style={{ fontSize: "10px" }} className='mt-1 float-end '>
                              <label className="toggle-switch ">
                                <input type="checkbox" checked={loginstatus === "FREE"} onChange={() => onPause()} />
                                <span className="switch " />
                              </label>

                            </div>
                          </div>

                        }
                      </div>


                    </div>
                  </div>



                  <div className=' row mb-1'>
                    <div className='col-sm-12 col-6  '>
                      <div className='col-sm-12 col-12  rounded badge bg-primary p-2' >
                        <div className=''>
                          <b className='float-start'>Call Sec:  </b>
                          <b style={{ fontSize: "10px" }} className='float-end'> {userstatus === "BUSY" ?
                            <>{Math.floor(Math.abs((new Date() - new Date(callanstime)) / 1000))}</>
                            : 0
                          }</b>
                        </div>
                      </div>
                    </div>
                  </div>

                  {
                  useridcrm == "636" ?
                            
                  <div className=' row mb-1'>
                    <div className='col-sm-12 col-6  '>
                      <div className='' >
                      <button
                          onClick={() => handleClickApicall(custmobile)}
                          className="col-sm-12 col-12  rounded badge bg-primary p-2"
                        >
                           API-CALL
                           </button>
                      </div>
                    </div>
                  </div>
                  :""}









                </div>
              </div>
            </div>








            <div className='col-sm-10 '>


              <div className='row'>
                <div className='col-md-3 '>
                  <div className='card fordashbg'>
                    <div className='text-white text-center bg-primary'>
                      Live Data
                    </div>
                    <div className='crmcard-body'>
                      <div className='small-boxdash'>
                        <div className=''>
                          <b>Campaign :  </b>
                          <b style={{ fontSize: "10px" }} className='float-end'>{camp}</b>
                        </div>
                        <div className=''>
                          <b>Status :  </b>
                          <b style={{ fontSize: "10px" }} className='float-end'>{userstatus}</b>
                        </div>
                        <div>
                          <b>Total Calls : </b>
                          <b style={{ fontSize: "10px" }} className='float-end'>{agenttotalcall}</b>
                        </div>
                        <div className=''>
                          <b>Caller : </b>
                          <b style={{ fontSize: "10px", color: "darkblue" }} className='float-end'>{hidenum ? hidecustmobile : custmobile}</b>
                        </div>
                      </div>
                    </div>
                    <div className=' bg-primary text-white text-center'>
                      {/* Number :-   {hidenum ? hidecustmobile : custmobile}                    */}
                    </div>
                  </div>
                </div>


                <div className='col-md-3 '>
                  <div className='card fordashbg'>
                    <div className='text-white text-center bg-primary'>
                      Live Data
                    </div>
                    <div className='crmcard-body'>
                      <div className=' small-boxdash'>
                        <div className=''>
                          <b>Sip :  </b>
                          <b style={{ fontSize: "10px" }} className='float-end'>{sip} | {sipstatus}</b>
                        </div>
                        <div className=''>
                          <b>CallType : </b>
                          <b style={{ fontSize: "10px" }} className='float-end'>{type}</b>

                        </div>
                        <div className=''>
                          <b>Total Login :  </b>
                          <b style={{ fontSize: "10px" }} className='float-end'>{agenttotallogintime}</b>
                        </div>

                        <div className=''>
                          <b>AutoCall :  </b>
                          <b style={{ fontSize: "10px" }} className='float-end'>{loginstatus}</b>
                        </div>
                      </div>
                    </div>
                    <div className=' bg-primary text-white text-center'>
                      {/* Number :-   {hidenum ? hidecustmobile : custmobile}                    */}
                    </div>
                  </div>

                </div>

                <div className='col-md-3'>
                  <div className='card fordashbg'>
                    <div className='text-center text-white  bg-primary'>
                      <>Live LeadManager</>
                    </div>
                    <div className='crmcard-body'>
                      <div className='small-boxdash'>
                        {/* <div className='text-center'>
                                <b style={{ fontSize: "15px" }} className=''> Lead-Manager : CallBack  </b>
                                
                              </div> */}
                        <div className=''>
                          <b><FcCallback className='me-1' /> Lead-Today-CB :  </b>
                          <b style={{ fontSize: "10px" }} className='float-end bg-warning  px-2'>{agenttotaltodaycallback}</b>
                        </div>


                        <div className=' '>
                          <b><FcCallback className='me-1' /> Lead-Missed-CB :  </b>
                          <b style={{ fontSize: "10px" }} className='float-end bg-danger  px-2'>{missedagenttodaycallback}</b>
                        </div>

                        <div className=''>
                          <b><FcCallback className='me-1' /> Lead-Total-CB : </b>
                          <b style={{ fontSize: "10px", color: "darkblue" }} className='float-end bg-warning  px-2'>{agenttotalcallback}</b>

                        </div>
                        <div className=''>
                          <b><FcCallback className='me-1' /> Lead-Total-CB : </b>
                          <b style={{ fontSize: "10px", color: "darkblue" }} className='float-end bg-warning  px-2'>{agenttotalcallback}</b>

                        </div>
                      </div>

                    </div>
                    <div className=' bg-primary text-white text-center'>
                      {/* Number :-   {hidenum ? hidecustmobile : custmobile}                    */}
                    </div>
                  </div>



                </div>
                <div className='col-md-3'>
                  <div className='card fordashbg'>
                    <div className='text-center text-white  bg-primary'>
                      <>Live Dialer</>
                    </div>
                    <div className='crmcard-body'>
                      <div className='small-boxdash'>
                        {/* <div className='text-center'>
                                <b style={{ fontSize: "15px" }} className=''> Lead-Manager : CallBack  </b>
                                
                              </div> */}
                        <div className=''>
                          <b><FcCallback className='me-1' /> Today-CB :  </b>
                          <b style={{ fontSize: "10px" }} className='float-end bg-warning  px-2'>{agenttotaltodaycallback}</b>
                        </div>


                        <div className=' '>
                          <b><FcCallback className='me-1' /> Missed-CB :  </b>
                          <b style={{ fontSize: "10px" }} className='float-end bg-danger  px-2'>{missedagenttodaycallback}</b>
                        </div>

                        <div className=''>
                          <b><FcCallback className='me-1' /> Total-CB : </b>
                          <b style={{ fontSize: "10px", color: "darkblue" }} className='float-end bg-warning  px-2'>{agenttotalcallback}</b>

                        </div>
                        <div className=''>
                          <b><FcCallback className='me-1' /> Total-CB : </b>
                          <b style={{ fontSize: "10px", color: "darkblue" }} className='float-end bg-warning  px-2'>{agenttotalcallback}</b>

                        </div>
                      </div>

                    </div>
                    <div className=' bg-primary text-white text-center'>
                      {/* Number :-   {hidenum ? hidecustmobile : custmobile}                    */}
                    </div>
                  </div>



                </div>




              </div>

              <div className='col-md-12 mt-1'>
                  {["BUSY", "HANGUP"].includes(userstatus) && (
                                  <form
                      className={`form-container ${
                        userstatus === "BUSY" ? "mybg-success" : userstatus === "HANGUP" ? "bg-warning" : ""
                      }`}
                    >
                      <div className="row p-3">
                        {fields && fields.map((field, i) => <Element key={i} field={field} />)}
                      </div>                 
                        <div className="row mt-1 p-2">
                          <div className="col-md-3"></div>
                          <div className="col-md-9 mb-1">
                            <label>Remarks:</label>
                            <textarea
                              className="form-control mb-1"
                              value={remark}
                              onChange={handleChangetxtarea}
                            />
                          </div>
                        </div>                
                          </form>
                    )}                
              </div>











            </div>





          </div>









        </div>
      </FormContext.Provider>



      {/* </div> */}
      <button ref={refconf} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModal">
        Launch demo modal
      </button>
      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Conference</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {/* <form className=" p-2" onSubmit={submiteditform}> */}

              <form className=" p-2" onSubmit={submitconfform}>

                {/* <form className=" p-2"> */}

                <div className=" mb-2 ">
                  <label className=" mb-1" htmlFor="number">Number:</label>
                  <input type="text" className="form-control" id="number" placeholder="Enter number to Conference"
                    name="number" value={number}
                    onChange={(e) => setNumber(e.target.value)} required />

                </div>
                <div className=" mb-2 ">

                  <div className='bg-info mt-3'>
                    ------------ OR-------------
                  </div>

                </div>

                <div className="mb-2">
                  <label className="form-label">Select Agent:</label>
                  <select id="agentfortrans" className="form-select" name="agentname"
                    value={agentname}
                    onChange={(e) => setAgentname(e.target.value)} required>
                    <option value="">-- Select --</option>
                    <option value="NA">NA</option>

                  </select>
                </div>
                <div className="d-grid gap-2">
                  <input className="btn btn-primary mt-3" type="submit" value="ADD IN Conference" />
                </div>

              </form>
            </div>
            <div className="modal-footer">
              {/* <button ref={refClose} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}

              {/*                             
                            <button disabled={campaign.etitle.length || campaign.edescription.length} onClick={handleClick} type="button" className="btn btn-primary">Update campaign</button>
                         */}

            </div>
          </div>
        </div>
      </div>




      <button ref={ref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModal2">
        Launch demo modal
      </button>
      <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Transfer</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {/* <form className=" p-2" onSubmit={submiteditform}> */}
              <form className=" p-2" onSubmit={transferAgentfun}>




                <div className=" mb-2 ">
                  <label className=" mb-1" htmlFor="number">Number:</label>
                  <input type="text" className="form-control" id="number" placeholder="Enter number to transfer"
                    name="number" value={number}
                    onChange={(e) => setNumber(e.target.value)} required />

                </div>
                <div className=" mb-2 ">

                  <div className='bg-info mt-3'>
                    ------------ OR-------------
                  </div>

                </div>

                <div className="mb-2">
                  <label className="form-label">Select Agent:</label>
                  <select id="agentfortrans" className="form-select" name="agentname"
                    value={agentname}
                    onChange={(e) => setAgentname(e.target.value)} required>
                    <option value="">-- Select --</option>
                    <option value="NA">NA</option>

                  </select>
                </div>
                {/* <div className="mb-2">
                                    <label  className="form-label">Select Audio:</label>
                                    <select id="sel" className="form-select" name="audio"
                                        value={eselectaudio}
                                        onChange={(e) => setEeselectaudio(e.target.value)} required>
                                        <option value="">-- Select --</option>
                                        <option value="NA">NA</option>

                                    </select>
                                </div> */}


                {/* <div className="mb-2">
                                    <label  className="form-label">Select List:</label>
                                    <select id="sellist" className="form-select" name="listname"
                                        value={eselectlist}
                                        onChange={(e) => setEeselectlist(e.target.value)} required>
                                        <option value="">-- Select List--</option>
                                        <option value="NA">NA</option>

                                    </select>
                                </div> */}




                <div className="d-grid gap-2">
                  <input className="btn btn-primary mt-3" type="submit" value="Transfer" />
                </div>

              </form>
            </div>
            <div className="modal-footer">
              {/* <button ref={refClose} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}

              {/*                             
                            <button disabled={campaign.etitle.length || campaign.edescription.length} onClick={handleClick} type="button" className="btn btn-primary">Update campaign</button>
                         */}

            </div>
          </div>
        </div>
      </div>


      {/*  click to call Model*/}
      <button ref={refclicktocall} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModal3">
        Launch demo modal
      </button>
      <div className="modal fade" id="exampleModal3" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Click To Call</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">

              <form className=" p-2" onSubmit={clicktocallsubmit}>




                <div className=" mb-2 ">
                  <label className=" mb-1" htmlFor="number">Number:</label>
                  <input type="text" className="form-control" id="number" placeholder="Enter number to Call"
                    name="number" value={number}
                    onChange={(e) => setNumber(e.target.value)} required />

                </div>




                <div className="d-grid gap-2">
                  <input className="btn btn-primary mt-3" type="submit" value="CALL" />
                </div>

              </form>
            </div>

          </div>
        </div>
      </div>



      {/*  Break Model  */}
      <button ref={refbreakmodel} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#modelrefbreakmodel">
        Launch demo modal
      </button>
      <div className="modal fade" id="modelrefbreakmodel" tabIndex="-1" aria-labelledby="modelrefbreakmodel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modelrefbreakmodel">Break</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">

              <form className=" p-2"
                onSubmit={onBreakSubmit}
              >

                <div className=" mb-2 ">
                  <label className="form-label">Break Reason:</label>
                  <select id="breakreasonid" className="form-select"
                    //value={eselectaudio}
                    onChange={(e) => setBreakreason(e.target.value)}
                  //</div>  required
                  >
                    <option value="">-- Select --</option>
                    <option value="TEA">TEA</option>

                  </select>
                </div>


                <div className=" mb-2 ">
                  <label className=" mb-1" htmlFor="number">Break Pwd:</label>
                  <input type="text" className="form-control" placeholder="Break Pwd"
                    value={breakpwd}
                    // autocomplete="123"
                    onChange={(e) => setBreakpwd(e.target.value)}
                    required />

                </div>




                <div className="d-grid gap-2">
                  <input className="btn btn-primary mt-3" type="submit" value="BREAK" />
                </div>

              </form>
            </div>

          </div>
        </div>
      </div>





      {/* <div className="slide-out-div mt-4 mb-5">

          {/* <img className="mx-auto d-block" style="filter: invert(1);"
								th:src="@{/images/c2cn.png}"/> */}


      {/* <img className="float-end ml-1 mb-5 " style={{borderRadius: '50% !important',marginBottom:'120px'}} src={require('../images/c2cnol.png')} alt="null" onClick={(e) => clicktocall(e)} />

        </div>  */}



    </>
  );


}

export default Crm;
